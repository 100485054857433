@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.breadcrumbs {
	@extend %font-xs;
	@include wrapper;

	span {
		display: flex;
		flex-wrap: wrap;
		row-gap: 1rem;
		column-gap: 2rem;
		align-items: center;
	}
}

.breadcrumbs__link {
	line-height: 1;
	color: colors.$grey-300;
	text-decoration: none;

	&:visited {
		color: inherit;
	}

	&:hover {
		cursor: pointers;
	}
}

.breadcrumbs__separator {
	display: block;
	width: 1rem;
	height: 1rem;
	background-color: colors.$orange;
}

.breadcrumb_last {
	font-weight: 700;
	line-height: 1;
	color: colors.$grey-400;
}

@media screen and (min-width: structure.$tablet-landscape) {
	.breadcrumbs {
		max-width: 100%;
		padding-top: 2.9rem;
		padding-bottom: 6.4rem;
		margin: 0;
	}

	.post__content-grid .breadcrumbs {
		padding-right: unset;
		padding-left: unset;
	}
}
