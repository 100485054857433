@use 'sass:map';
@use '../../variables/columns';
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

@mixin wrapper($width: structure.$size-site) {
	width: 100%;
	max-width: $width;
	padding-right: 2rem;
	padding-left: 2rem;
	margin-right: auto;
	margin-left: auto;

	@media screen and (min-width: structure.$tablet-landscape) and (max-width: structure.$big-desktop - 1) {
		padding-right: 12rem;
		padding-left: 12rem;
	}
}

@mixin button($back-color, $text-color, $border-color: $back-color) {
	@extend %font-sm;
	display: inline-block;
	padding: 1rem 2rem;
	font-size: 1.6rem;
	font-weight: 700;
	color: $text-color;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	background: $back-color;
	border: 1px solid $border-color;
	border-radius: 0.4rem;
	transition: color ease-in-out 0.4s, background-color ease-in-out 0.4s,
		border-color ease-in-out 0.4s;

	&:active,
	&:focus,
	&:hover {
		background-color: $back-color;
		border-color: $back-color;
	}

	&:visited {
		color: $text-color;
	}
}

// Center block
@mixin center-block {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map.get(columns.$columns, $numberColumns) -
		((columns.$columns__margin * ($numberColumns - 1)) / $numberColumns);
}
